import React, {FunctionComponent} from 'react';
import {Option, Options} from './model';
import {useField} from 'formik';

export interface OptionPickerProps<TOption = Option> {
	name: string;
	id?: string;
	options: Options<TOption>;
	required?: boolean;
}

export type OptionPickerFC<TOption = Option, TProps extends OptionPickerProps<TOption> = OptionPickerProps<TOption>> = FunctionComponent<TProps>;

const OptionPicker: OptionPickerFC = (props) => {
	const [field, , helpers] = useField(props.name);
	const {setValue} = helpers;
	const selectedOption = field.value ? props.options.options.find((x) => x.id === field.value) : null;

	const required = props.required ?? true;

	return (
		<div className="dropdown">
			<div className="form-select form-control-lg py-1 px-3 d-flex align-items-center" tabIndex={0} data-bs-toggle="dropdown" aria-expanded="false">
				<div className="flex-fill d-flex align-items-center text-truncate">
					<span className="fs-5 text-truncate">{selectedOption?.name}</span>
				</div>
			</div>
			<ul className="dropdown-menu dropdown-menu-lg" style={{minWidth: '100%'}}>
				{!required && (
					<li className="dropdown-item d-flex align-items-center" onClick={() => setValue(undefined)}>
						<span className="fs-5">Geen</span>
					</li>
				)}

				{props.options.options.map((option, index) => {
					return (
						<li key={index} className="dropdown-item d-flex align-items-center" onClick={() => setValue(option.id)}>
							<span className="fs-5">{option.name}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default OptionPicker;
