import React, { useMemo } from "react";
import { API_GATEWAY_BASE_URL } from "../../constants";

export interface ProfilePreviewSvgProps {
    profileSvg?: string | null;
    profileA?: string | null;
    profileB?: string | null;
    svgWidth?: string;
    mirror?: boolean;
    svgHeight?: string;
    cacheBuster?: number | string | null;
}

const ProfilePreviewSvg: React.FC<ProfilePreviewSvgProps> = ({
                                                                 profileSvg,
                                                                 profileA,
                                                                 profileB,
                                                                 svgWidth = "100%",
                                                                 mirror = false,
                                                                 svgHeight = "100%",
                                                                 cacheBuster
                                                             }) => {
    const imgSrc = useMemo(() => {
        if (!profileA) return undefined;

        let src = `${API_GATEWAY_BASE_URL}/api/render/preview/profileMatch?geometryA=${encodeURIComponent(profileA)}`;
        if (profileB) src += `&geometryB=${encodeURIComponent(profileB)}`;
        if (cacheBuster) src += `&cacheBuster=${cacheBuster}`;

        return src;
    }, [profileA, profileB, cacheBuster]);

    if (!profileSvg && !profileA) return null;

    if (profileSvg) {
        return (
            <div
                className={`preview-svg ${mirror ? 'mirror' : ''}`}
                dangerouslySetInnerHTML={{ __html: profileSvg }}
            />
        );
    }

    return (
        <img
            src={imgSrc}
            style={{
                width: svgWidth,
                height: svgHeight,
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
            }}
            alt=""
        />
    );
};

export default ProfilePreviewSvg;