import React, {
    useRef,
    useState,
    useEffect,
    useImperativeHandle,
    forwardRef,
} from "react";

interface WebcamStreamProps {
    facingMode?: "user" | "environment"; // Select front or back camera
    onError?: (errorMessage: string) => void; // Handle errors
}

const WebcamStream = forwardRef((props: WebcamStreamProps, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null); // Ref for the <video> element
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [camIndexToUse, setCamIndexToUse] = useState<number>(1);

    useEffect(() => {
    }, [camIndexToUse]);

    useEffect(() => {
        startCamera(); // Automatically start the camera on mount
        return () => stopCamera(); // Clean up the camera when unmounting
    }, []); // Empty dependency array to run only once on mount
    // Start the camera
    const startCamera = async () => {
        try {
            const facingMode = camIndexToUse === 0 ? "user" : "environment";
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoInputs = devices.filter(
                (device) => device.kind === "videoinput"
            );

            if (videoInputs.length === 0) {
                throw new Error("No cameras found.");
            }

            const selectedCamera = videoInputs[camIndexToUse];
            const constraints = {
                video: {
                    facingMode: facingMode, // Use appropriate facing mode
                },
            };
            const videoStream = await navigator.mediaDevices.getUserMedia(
                constraints
            );

            setStream(videoStream);
            if (videoRef.current) {
                videoRef.current.srcObject = videoStream;
                videoRef.current.play();
            }
        } catch (err: any) {
            const errorMessage =
                err.message || "An error occurred while starting the camera.";
            if (props.onError) props.onError(errorMessage);
        }
    };
    
    const getVideoInputs = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(
            (device) => device.kind === "videoinput"
        );
    } 
    
    const changeCamera = async () => {
        stopCamera();
        try {
            const videoInputs = await getVideoInputs();
            if (videoInputs.length === 0) {
                throw new Error("No cameras found.");
            }
            let newIndexToUse = camIndexToUse + 1;
            if(videoInputs.length <= newIndexToUse) {
                newIndexToUse = 0;
            }
            setCamIndexToUse(newIndexToUse);
            const newFacingMode = camIndexToUse === 0 ? "environment" : "user";

            const constraints = {
                video: {
                    facingMode: newFacingMode, // Wissel tussen 'user' en 'environment'
                },
            };
            const videoStream = await navigator.mediaDevices.getUserMedia(
                constraints
            );
            setStream(videoStream);
            if (videoRef.current) {
                videoRef.current.srcObject = videoStream;
                await videoRef.current.play();
            }
        } catch (err: any) {
            const errorMessage =
                err.message || "An error occurred while switching the camera.";
            if (props.onError) props.onError(errorMessage);
        }

    }

    // Stop the camera
    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
    };

    // Capture an image from the video
    const captureImage = (): string | null => {
        if (!videoRef.current) return null;

        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        const context = canvas.getContext("2d");
        if (context) {
            context.drawImage(
                videoRef.current,
                0,
                0,
                canvas.width,
                canvas.height
            );
            return canvas.toDataURL("image/jpeg"); // Return Base64 string
        }

        return null;
    };

    // Expose functions to parent using `ref`
    useImperativeHandle(ref, () => ({
        startCamera,
        changeCamera,
        stopCamera,
        captureImage,
    }));

    // Cleanup stream on unmount
    useEffect(() => {
        return () => stopCamera();
    }, [stream]);

    return (
        <div>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: "100%", height: "auto" }}
            ></video>
        </div>
    );
});

export default WebcamStream;