import React, {useEffect, useState} from "react";
import SignaturePad from "signature_pad";
import {Button} from "react-bootstrap";
import {useFileUploadMutation} from "../../../../../shared/components/file-upload/useFileUploadMutation";
import {FormValues} from "../../../../../shared/components/file-upload/FileModal";
import * as api from "../../../../../modules/api-client/generated";
import {Form, Formik, FormikHelpers} from "formik";
import SubmitButton from "../../../../../shared/components/SubmitButton";

interface SignatureCanvasProps {
    title: string,
    altText: string,
    description: string,
    fileCategory: api.FileCategory,
    saveFileId: (id: undefined | string) => void;
}

const SignatureCanvas: React.FC<SignatureCanvasProps> = (props) => {
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
    const [file, setFile] = useState<File | null>(null);
    let {mutate, data: fileId} = useFileUploadMutation();
    const [isSignatureSaved, setSignatureSaved] = useState(false);

    const readyPad = () => {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        if (canvas) {
            canvas.style.border = "1px solid black";
            canvas.width = 600;
            canvas.height = 300;
            setSignaturePad(new SignaturePad(canvas, { backgroundColor: "rgb(255, 255, 255)" }));
        }
    };

    function base64ToPng(base64: string, fileName: string): File {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([new Blob([ab], { type: mimeString })], fileName, { type: mimeString });
    }

    const handleClearSignature = () => {
        setSignatureSaved(false);
        props.saveFileId(undefined);
        signaturePad?.clear();
    };

    useEffect(() => {
        if(fileId && isSignatureSaved)
            props.saveFileId(fileId);
    }, [fileId, isSignatureSaved]);

    useEffect(() => {
        readyPad();
    }, []);

    const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
        setSignatureSaved(true);
        values.file = base64ToPng(signaturePad!.toDataURL(), 'signature.png');
        try {
            return await new Promise<void>((resolve, reject) => {
                mutate(values, {
                    onSuccess: () => resolve(),
                    onError: () => reject(),
                });
            });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues : FormValues = {
        file: file!,
        fileCategory: props.fileCategory,
        altText: props.altText,
        title: props.title,
        description: props.description,
    }

    return (
        <>
            {fileId ? (
                <>Handtekening opgeslagen</>
            ) : (
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="d-flex flex-column align-items-center pt-3">
                                <h3>{props.title}</h3>
                                <div id="signature-pad">
                                    <canvas className="signature-canvas"></canvas>
                                </div>
                                <div className="mt-2">
                                    <Button className="btn btn-secondary me-2" onClick={handleClearSignature}>
                                        Reset
                                    </Button>
                                    <SubmitButton type="submit" className="btn btn-primary" isSubmitting={isSubmitting} disabled={isSignatureSaved}>
                                        {isSignatureSaved ? 'Opgeslagen' : 'Opslaan' }
                                    </SubmitButton>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
}

export {SignatureCanvas};


