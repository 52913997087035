import React, {useState, useEffect} from "react";
import DeliverOrdersOverview from "./DeliverOrdersOverview";
import DeliverOrdersAddProof from "./DeliverOrdersAddProof";
import {useQuery} from "@tanstack/react-query";
import {ExpeditionForProductionItemRepresentation} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {usePutExpeditionListDelivered} from "../../../sm/orders/hooks/use-put-expedition-status";

interface DeliverOrdersProps {
    orderId: string,
    handleClose: () => void
}
const DeliverOrders: React.FC<DeliverOrdersProps> = ({ orderId, handleClose}) => {
    const [areOrdersDelivered, setAreOrdersDelivered] = useState<boolean>(false);
    const [ordersToDeliver, setOrdersToDeliver] = useState<string[]>([]);

    const {
        isInitialLoading,
        data: expeditionData,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet', orderId], () =>
        ApiClient.Em.Expedition.emExpeditionOrderIdGet(orderId!).then((res) => res.data));

    var inTransitCount = 0;
    if(expeditionData) {
        inTransitCount = expeditionData.relatedExpeditions[expeditionData.expedition.plannedExpeditionDate!]?.expeditions
            .filter((e: { expeditionStatus: string; }) => e.expeditionStatus === "inTransit").length;
    }
    const deliveredListMutation = usePutExpeditionListDelivered();

    const updateExpeditionStatusToDelivered = React.useCallback(async (orderIds: string[]) => {
        setOrdersToDeliver(orderIds);
        deliveredListMutation.mutate(orderIds, {
            onSuccess: () => setAreOrdersDelivered(true),
            onError: () => { }
        });
    }, [deliveredListMutation]);

    useEffect(() => {
        if (inTransitCount === 1 && !areOrdersDelivered) {
            setOrdersToDeliver([orderId]);
            updateExpeditionStatusToDelivered([orderId]).then(r => {});
        }
    }, [inTransitCount, orderId]);

    if(isInitialLoading || !expeditionData) {
        return <Loading/>
    }
    if(isError) {
        return <div>Error...</div>
    }

    if(!areOrdersDelivered) {
        return (
            <DeliverOrdersOverview
                orderId={orderId}
                updateExpeditionStatusToDelivered={updateExpeditionStatusToDelivered}
            />
        );
    } else {
        return (
            <DeliverOrdersAddProof
                orderIdToQuery={orderId}
                deliveredOrders={ordersToDeliver}
                handleClose={function (): void {
                    handleClose();
                }}
            />
        )
    }
}
export default DeliverOrders
