/* eslint-disable jsx-a11y/anchor-is-valid */
import {useNavigate, useParams} from 'react-router-dom';
import {useGetImportValidation} from '../hooks/use-get-import-validation';
import Loading from '../../components/Loading';
import {
	GroeneveldCompartmentLayoutImportValidation,
	GroeneveldCompartmentProfileMissingImportValidation,
	GroeneveldCompartmentProfileSuggestionImportValidation,
	GroeneveldHefschuifFixedDoorCutImportValidation,
	GroeneveldHefschuifFixedDoorMissingImportValidation,
	GroeneveldHefschuifFixedDoorRaisedRimMissingImportValidation,
	GroeneveldHefSchuifSchemaLayoutImportValidation,
	GroeneveldHefschuifSlidingDirectionMissingImportValidation,
	GroeneveldHefschuifSlidingDoorMissingImportValidation,
	GroeneveldHefschuifSlidingRailCenterImportValidation,
	GroeneveldWallConnectionImportValidation,
	HefschuifDoorPositionMissingImportValidation,
	ImportValidation,
	ImportValidationType,
	MappingImportValidation,
} from '../../../modules/api-client/generated';
import {SillTypeResolver} from '../components/validationResolvers/SillTypeResolver';
import {modalsRoot} from '../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {SluitpotResolver} from '../components/validationResolvers/SluitpotResolver';
import {ProfileResolver} from '../components/validationResolvers/ProfileResolver';
import {CompartmentLayoutResolver} from '../components/validationResolvers/CompartmentLayoutResolver';
import {HefSchuifSchemaResolver} from '../components/validationResolvers/HefSchuifSchemaResolver';
import {UitsparingRubberBlokResolver} from "../components/validationResolvers/UitsparingRubberBlokResolver";
import {SlidingDirectionResolver} from "../components/validationResolvers/SlidingDirectionResolver";
import {FixedDoorBarMissingResolver} from "../components/validationResolvers/FixedDoorBarMissingResolver";
import {SlidingRailCenterMissingResolver} from "../components/validationResolvers/SlidingRailCenterResolver";
import { SlidingDoorBarMissingResolver } from '../components/validationResolvers/SlidingDoorBarMissingResolver';
import { FixedDoorBarRaisedRimMissingResolver } from '../components/validationResolvers/FixedDoorBarRaisedRimMissingResolver';
import { InnerDoorPositionResolver } from '../components/validationResolvers/InnerDoorPositionResolver';
import {WallconnectionResolver} from "../components/validationResolvers/WallconnectionResolver";

export type ValidationResolverProps = {
	validation: ImportValidation;
	show: boolean;
	handleClose: () => void;
};

export type MappingValidationResolverProps = {
	validation: MappingImportValidation;
	show: boolean;
	handleClose: () => void;
};


export function ValidationResolverPage() {
	const navigate = useNavigate();
	const {id: importId, customerId, validationId} = useParams<{id: string; customerId: string; validationId: string}>();
	const {isLoading, data: importValidation, isError} = useGetImportValidation(validationId);

	if (isLoading) {
		return <Loading />;
	}

	if (!importValidation) {
		return <>Geen validatie gevonden</>;
	}

	if (isError) {
		return <>Fout bij het ophalen van de validatie!</>;
	}
	const renderValidationComponent = () => {
		switch (importValidation.validationType) {
			case ImportValidationType.GroeneveldSillMappingImportValidation:
				return <SillTypeResolver validation={importValidation as MappingImportValidation} handleClose={() => navigate(-1)} show={true} />;
			case ImportValidationType.GroeneveldSluitpotBeslagMappingImportValidation:
				return <SluitpotResolver validation={importValidation as MappingImportValidation} handleClose={() => navigate(-1)} show={true} />;
			case ImportValidationType.GroeneveldCompartmentLayoutImportValidation:
				return <CompartmentLayoutResolver validation={importValidation as GroeneveldCompartmentLayoutImportValidation} handleClose={() => navigate(-1)} show={true} />;
			case ImportValidationType.GroeneveldHefSchuifSchemaLayoutImportValidation:
				return <HefSchuifSchemaResolver validation={importValidation as GroeneveldHefSchuifSchemaLayoutImportValidation} handleClose={() => navigate(-1)} show={true} />;
			case ImportValidationType.GroeneveldCompartmentProfilesNotEqualImportValidation:
				// dit is niet correct, todo implement:
			case ImportValidationType.GroeneveldCompartmentProfileMissingImportValidation:
				return <ProfileResolver customerId={customerId!} validation={importValidation as GroeneveldCompartmentProfileMissingImportValidation} handleClose={() => navigate(-1)} show={true} />;
			case ImportValidationType.GroeneveldCompartmentProfileSuggestionImportValidation:
				return <ProfileResolver customerId={customerId!} validation={importValidation as GroeneveldCompartmentProfileSuggestionImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifFixedDoorCutImportValidation:
				return <UitsparingRubberBlokResolver validation={importValidation as GroeneveldHefschuifFixedDoorCutImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifSlidingDirectionMissingImportValidation:
				return <SlidingDirectionResolver validation={importValidation as GroeneveldHefschuifSlidingDirectionMissingImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifFixedDoorMissingImportValidation:
				return <FixedDoorBarMissingResolver validation={importValidation as GroeneveldHefschuifFixedDoorMissingImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifSlidingDoorMissingImportValidation:
				return <SlidingDoorBarMissingResolver validation={importValidation as GroeneveldHefschuifSlidingDoorMissingImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifSlidingRailCenterImportValidation:
				return <SlidingRailCenterMissingResolver validation={importValidation as GroeneveldHefschuifSlidingRailCenterImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldHefschuifFixedDoorRaisedRimMissingImportValidation:
				return <FixedDoorBarRaisedRimMissingResolver validation={importValidation as GroeneveldHefschuifFixedDoorRaisedRimMissingImportValidation} handleClose={() => navigate(-1)} show={true} />				
			case ImportValidationType.GroeneveldHefschuifDoorPositionMissingImportValidation:
				return <InnerDoorPositionResolver validation={importValidation as HefschuifDoorPositionMissingImportValidation} handleClose={() => navigate(-1)} show={true} />
			case ImportValidationType.GroeneveldWallconnectionImportValidation:
				return <WallconnectionResolver validation={importValidation as GroeneveldWallConnectionImportValidation} handleClose={() => navigate(-1)} show={true} />
			default:
				return (
					<Modal
						container={modalsRoot}
						tabIndex={-1}
						aria-hidden="true"
						autoFocus={true}
						className="configurator-log-modal"
						dialogClassName={'modal-dialog-centered'}
						contentClassName={'shadow-lg'}
						show={true}
						onHide={() => navigate(-1)}
						size="lg"
						animation={false}
						backdrop={'static'}
					>
						<Modal.Header closeButton>
							<Modal.Title>Deze validatie kan alleen door Ekosiet medewerkers worden opgelost.</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">Neem contact op met Ekosiet.</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={() => navigate(-1)}>
								Sluit
							</Button>
						</Modal.Footer>
					</Modal>
				);
		}
	};

	return <>{renderValidationComponent()}</>;
}
