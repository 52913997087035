import {
    SillConfigurationAngledWallConnection, ConfiguratorWallConnectionAngle, SillConfigurationStandardWallConnection
} from "../../../modules/api-client/generated";
import React from "react";
import {useFormikContext} from "formik";

export interface AngledWallConnectionEditorProps {
    width: number;
    fieldName: string;
    angles?: ConfiguratorWallConnectionAngle[];
}

export const AngledWallConnectionEditor: React.FC<AngledWallConnectionEditorProps> = (props) => {

    const { getFieldProps, setFieldValue } = useFormikContext();
    const fieldProps = getFieldProps(props.fieldName);
    const values:SillConfigurationAngledWallConnection = fieldProps.value;
    return <div>
        <label className='form-label'><small className='text-uppercase text-muted'>Hoek</small></label>
        <select className='form-select' value={values.angle} onChange={(e) => setFieldValue(props.fieldName, {...values, angle: Number(e.target.value)})}>
            {props.angles?.map((angle, index) => <option key={index} value={angle.angle}>{angle.name}</option>)}
        </select>
    </div>
};