import React, {useEffect, useRef, useState} from 'react';
import WebcamStream from "./WebcamStream";
import {useFileUploadMutation} from '../../../../../shared/components/file-upload/useFileUploadMutation';
import {FormValues} from '../../../../../shared/components/file-upload/FileModal';
import * as api from '../../../../../modules/api-client/generated';
import {Form, Formik, FormikHelpers} from 'formik';
import {Button} from 'react-bootstrap';
import SubmitButton from '../../../../../shared/components/SubmitButton';

interface PhotoCaptureProps {
    title: string,
    altText: string,
    description: string,
    fileCategory: api.FileCategory,
    saveFileId: (id: string) => void;
}

const PhotoCapture: React.FC<PhotoCaptureProps> = (props) => {
    const webcamRef = useRef<any>(null); // Ref for WebcamStream
    const {mutate, serverValidationErrors, data: fileId} = useFileUploadMutation();
    const [photo, setPhoto] = useState<string | null>(null); // Captured photo as Base64
    const [file, setFile] = useState<File | null>(null);
    const [isPhotoSaved, setPhotoSaved] = useState(false);

    useEffect(() => {
        if(fileId && isPhotoSaved)
            props.saveFileId(fileId);
    }, [fileId, isPhotoSaved]);

    // Function to start the webcam
    const startWebcam = () => {
        webcamRef.current?.startCamera();
    };

    // Function to stop the webcam
    const stopWebcam = () => {
        webcamRef.current?.stopCamera();
    };

    const changeCamera = () => {
        webcamRef.current?.changeCamera();
    };

    // Function to capture the image
    const capturePhoto = () => {
        const capturedImage = webcamRef.current?.captureImage();
        if (capturedImage) {
            setPhoto(capturedImage); // Save the captured image in state
            stopWebcam(); // Optionally stop the webcam after capturing
            setFile(base64ToJpeg(capturedImage, 'deliveryPhoto.jpg'));
        }
    };

    // Function to retake the photo
    const retakePhoto = () => {
        setPhotoSaved(false);
        setPhoto(null); // Clear captured photo
        startWebcam(); // Restart the webcam
    };

    // Function to save the photo
    const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
        if(file == null) return;
        setPhotoSaved(true)
        try {
            return await new Promise<void>((resolve, reject) => {
                mutate(values, {
                    onSuccess: () => {
                        resolve();
                    },
                    onError: (error) => {
                        console.log('error', error);
                        console.log('values', values);
                        reject();
                    },
                });
            });

            // Convert Base64 to Blob
        } catch (err) {
            console.error("Failed to save photo:", err);
            alert("Fout bij het opslaan van de foto. Probeer het a.u.b. opnieuw.");
        } finally {
            setSubmitting(false);
        }
    };

    function base64ToJpeg(base64: string, fileName: string): File {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return new File([blob], fileName, { type: mimeString });
    }

    const initialValues : FormValues = {
        file: file!,
        fileCategory: props.fileCategory,
        altText: props.altText,
        title: props.title,
        description: props.description,
    }

    return (
        <div>
            <div className="d-flex flex-column align-items-center pt-3">
                <h3>Maak een foto</h3>
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            {!photo ? (
                                <>
                                    <WebcamStream
                                        ref={webcamRef}
                                        facingMode="environment" // Use back camera
                                        onError={(err) => alert(`Webcam error: ${err}`)}
                                    />
                                    <div className="mt-2">
                                        <Button className="btn btn-secondary my-custom-class" onClick={changeCamera}>
                                            Wijzig camera
                                        </Button>
                                        <Button className="btn btn-primary my-custom-class" onClick={capturePhoto}>
                                            Neem foto
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <img src={photo} alt="Captured" style={{ width: "100%" }} />
                                    <div className="mt-2">
                                        <Button className="btn btn-secondary my-custom-class" onClick={retakePhoto}>
                                            Verwijder foto
                                        </Button>
                                        <SubmitButton
                                            className="btn btn-primary my-custom-class"
                                            type="submit"
                                            isSubmitting={isSubmitting}
                                            disabled={isPhotoSaved}
                                        >
                                            {isSubmitting ? "Opslaan..." : "Opslaan"}
                                        </SubmitButton>
                                    </div>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PhotoCapture;