import React, {useEffect, useMemo, useState} from 'react';
import { useFormikContext } from 'formik';
import { StandardWallConnectionEditor } from './StandardWallConnectionEditor';
import {
    WallConnectionType,
    SillConfigurationStandardWallConnection,
    SillConfigurationAngledWallConnection,
    SillConfigurationWallConnection,
    ConfiguratorWallConnectionAngle, Vertex,
} from '../../../modules/api-client/generated';
import ApiClient from '../../../modules/api-client/ApiClient';
import WallConnectionPreview from '../WallConnectionPreview';
import { AngledWallConnectionEditor } from "./AngledWallConnectionEditor";
import WallConnectionComparisonSvg from "../WallConnectionComparisonSvg";

export interface WallConnectionEditorOptions {
    width: number;
    position: 'left' | 'right';
    originalGeometry?:  string|undefined;
    angles?: ConfiguratorWallConnectionAngle[];
}

export interface WallConnectionEditorProps<T> extends WallConnectionEditorOptions {
    id: string;
    fieldName: string;
}

export type WallConnectionEditorValue = SillConfigurationStandardWallConnection | SillConfigurationAngledWallConnection;

const WallConnectionEditor = <T extends WallConnectionEditorValue>({ id, fieldName, ...props }: WallConnectionEditorProps<T>) => {
    const [previewSvg, setPreviewSvg] = useState<string | null>(null);
    const [isPreviewLoading, setPreviewLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const { getFieldProps, setFieldValue, isValid } = useFormikContext<T>();

    const fieldProps = getFieldProps(fieldName);
    const values:WallConnectionEditorValue = fieldProps.value;

    const validate = async (wc: SillConfigurationWallConnection): Promise<void> => {
        setPreviewLoading(true);
        try {
            const response = await ApiClient.Pim.Configurator.validateWallConnection(
                props.width,
                props.position === 'right',
                props.originalGeometry,
                undefined,
                wc)
                .then(x => x.data);

            if (response.isValid) {
                setPreviewSvg(response.svg!);
            } else {
                throw new Error(response.error ?? 'Muuraansluiting is niet geldig');
            }
        } finally {
            setPreviewLoading(false);
        }
    };

    useEffect(() => {
        validate(values).then();
    }, [values]);

    const isAngleSupported = () => {
        return props.angles && props.angles.length > 0;
    };

    const setType = async (value: WallConnectionType) => {
        let newValue: WallConnectionEditorValue;

        switch (value) {
            case WallConnectionType.Angled: {
                if (!isAngleSupported()) return;
                const initialAngle = props.angles![0].angle;
                newValue = { wallConnectionType: value, angle: initialAngle };
                break;
            }
            default: {
                newValue = { wallConnectionType: value };
                break;
            }
        }

        await setFieldValue(fieldName, newValue);
        await validate(newValue);
    };

    return (
        <div className='wall-connection-editor'>
            <div className='row'>
                <div className='col-4 d-flex align-items-center'>
                    <WallConnectionPreview svg={previewSvg} isLoading={isPreviewLoading} />

                </div>
                <div className='col' style={{ minHeight: '340px' }}>
                    <div className='d-grid gap-3'>
                        <div>
                            <label className='form-label'><small className='text-uppercase text-muted'>Type aansluiting</small></label>
                            <select id={`${id}_type`} className='form-select'
                                    value={values.wallConnectionType}
                                    onChange={(e) => setType(e.target.value as WallConnectionType)}>
                                <option value={WallConnectionType.Standard}>Sponning en/of groef</option>
                                <option value={WallConnectionType.Angled} disabled={!isAngleSupported()}>Schuin</option>
                            </select>
                        </div>
                        {error && <div className="alert alert-danger">{error.message}</div>}
                        {values.wallConnectionType === WallConnectionType.Standard &&
                            <StandardWallConnectionEditor width={props.width} fieldName={'wallconnection'}/>
                        }
                        {values.wallConnectionType === WallConnectionType.Angled &&
                            <AngledWallConnectionEditor width={props.width} fieldName={'wallconnection'} angles={props.angles} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WallConnectionEditor;

