/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
	AngledWallConnection, GroeneveldWallConnectionImportValidation,
	IgnoreImportValidationCommand, RenovationRabbet,
	ResolveImportValidationCommandType,
	ResolveWallconnectionImportValidationCommand, SillConfigurationAngledWallConnection,
	SillConfigurationStandardWallConnection, StandardWallConnection,
	WallConnection, WallConnectionGroove,
	WallConnectionType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import WallConnectionComparisonSvg from "../../../components/WallConnectionComparisonSvg";
import WallConnectionEditor, {
	WallConnectionEditorOptions,
	WallConnectionEditorValue
} from "../../../components/configurator/WallConnectionEditor";
import clsx from "clsx";


export type WallconnectionValidationResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: GroeneveldWallConnectionImportValidation
};
type FormValues = {
	wallconnection:WallConnectionEditorValue,
	applyToAll: boolean;
};
export const WallconnectionResolver = ({show, validation, handleClose, children}: PropsWithChildren<WallconnectionValidationResolverProps>) => {
	var resolveMutation = usePutValidationResolve();



	const initialValues: FormValues = {
		wallconnection: {
			wallConnectionType: WallConnectionType.Standard,
			...validation.wallConnection
		},
		applyToAll: false,
	};


	const wallConnectionEditorOptions: WallConnectionEditorOptions = {
		width: validation.wallConnection?.width??114,
		position: validation.isLeftWallConnection ? 'left' : 'right',
		originalGeometry: validation.gaGeometry,
		angles: [],
	}

	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveWallconnectionImportValidationCommand = {
				applyToAll: values.applyToAll,
				wallconnection: values.wallconnection,
				commandType: ResolveImportValidationCommandType.ResolveWallconnectionImportValidationCommand,
				id: validation.id,
				importType: validation.type
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Configureer muuraansluiting</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<p className={'lead'}>Merk {validation.merk}: {validation.message} </p>
									<WallConnectionEditor id={'wall-connection-editor'} {...wallConnectionEditorOptions} fieldName={'wallconnection'} />
							<div className="row mb-5">
								<div className={'col-4'}>
									<label className="fw-bold fs-6 mb-2"></label>
								</div>
								<div className={'col'}>
									<div className="form-check form-switch">
										<input
											{...getFieldProps('applyToAll')}
											className={clsx('form-check-input')}
											type="checkbox"
											name="applyToAll"
											id="applyToAll"
											value={values.applyToAll ? 'true' : 'false'}
											checked={!!values.applyToAll}
											disabled={isSubmitting}
										/>
										<label className="fw-bold fs-6 mb-2 form-check-label" htmlFor="applyToAll">
											Toepassen op alle gelijke muuraansluitingen binnen de import
										</label>
									</div>
								</div>
								</div>
						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton 
								type="submit" 
								className="btn btn-primary" 
								spinning={isSubmitting}
								disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};